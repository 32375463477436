.info-panel {
  text-align: right;
  color: #eee;
  text-shadow: 0.1em 0.1em #666;
  padding: 1em;
}

.info-panel-heading {
  font-size: 2em;
  padding-bottom: 0.25em;
}

.info-panel-description {
  font-size: 1.75em;
  padding-bottom: 0.5em;
}

.info-panel-entry {
  font-size: 1.5em;
  padding-bottom: 0.5em;
}
